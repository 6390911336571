const clearTag = (text) => {
  const replaceTagRegex = /<([^'">]+|[^'">]+")*>/gmi
  if (!text) return text
  while (text.match(replaceTagRegex) !== null) {
    text = text.replace(text.match(replaceTagRegex)[0], '')
  }
  return text
}

export {
  clearTag,
}
